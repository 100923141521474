<template>
    <div class="hidden-danger-view">
        <div class="ledger-shopsearch">
            <LedgerList title="隐患台账" :list="ledgerList" @onClick="onLedgerClick" :active="ledgerActive"/>
            <ShopSearch title="企业列表" :list="shopList" @onSearch="onShopSearch" @onClick="onShopClick" :active="shopActive"/>
        </div>
        <div :class="{'table-charts-view': true, charts: !ledgerActive}">
            <div class="table-modal" v-if="ledgerActive">
                <TableList title="隐患台账清单" @onClick="onTableClose" />
            </div>
            <div class="charts-modal" v-if="!ledgerActive">
                <ChartsView 
                :hiddenDangerSourceList="hiddenDangerSourceListFormat" 
                :hiddenDangerCatetoryList="hiddenDangerCatetoryListFormat"
                :dangerControlStatusList="dangerControlStatusListFormat"
                @onChange="onDateChange" 
                />
            </div>
        </div>
        <div class="footer-cricle-view" v-if="!ledgerActive">
            <div class="item" v-for="item in hiddenDangerLevelListFormat" :key="item.name">
                <CircleNumber :data="item" />
            </div>
        </div>
    </div>
</template>

<script>

import EventBus from '@/commons/event-bus.js'

import LedgerList from '@/components/LedgerList.vue';
import ShopSearch from '@/components/ShopSearch.vue';
import CircleNumber from "@/components/CircleNumber.vue";
import TableList from './table-list/Index.vue'
import ChartsView from './ChartsView.vue'

const hiddenDangerLevel = {
    '管理因素': '#E86452',
    '物的不安全状态': '#FFB462',
    '人的不安全行为': '#FFD470',
    '环境的不安全因素': '#2385FF'
}


const HiddenDangerName = {
    overdue_unfinish_total: {name: '逾期未整改',color: '#FFB462' },
    again_total: {name: '再整改', color: '#FFD470' },
    doing_total: {name: '整改中', color: '#2385FF' },
} 

const riskNameType = {
    danger0: {id:'0', name: '无隐患', color: '#FFFFFF' },
    danger1: {id:'1', name: '重大隐患', color: '#E86452' },
    danger2: {id:'2', name: '一般隐患', color: '#FFB462' },
}

export default {
    name: 'HiddenDanger',
    components: { LedgerList, ShopSearch, TableList, ChartsView, CircleNumber },
    data(){
        return {
            ledgerActive: null,
            shopActive: null,
            ledgerList: [
               { key: '1', name: '隐患台账清单' }
            ],
            enterpriseList: [], //左侧企业列表 可搜索
            enterpriseRiskList: [], //右侧风险分布数据
            shopList: [], // 左侧企业搜索结果
            hiddenDangerSourceList: [], // 隐患原因分布
            enterpriseHiddenDangerList: [], // 隐患状态分布
            hiddenDangerCatetoryList: [], // 隐患分类
            hiddenDangerLevelList: [], // 隐患等级
            dateRange: {
                start_date: "",
                end_date: ""
            }
        }
    },
    computed:{
       hiddenDangerLevelListFormat(){
           return this.hiddenDangerLevelList.map(item=>{
                const {
                    hidden_danger_source_id: id,
                    hidden_danger_level_name: name,
                    total: value
                } = item;
               return { name, id, value };
           }) 
       },
       hiddenDangerSourceListFormat(){
           return this.hiddenDangerSourceList.map(item=>{
                const {
                    hidden_danger_source_name: name,
                    total: value
                } = item;
               return { name, value: Number(value), color: hiddenDangerLevel[name], y: Number(value) };
           }) 
       
       },
       hiddenDangerCatetoryListFormat(){
            let xdata = [];
            let ydata = [];
            this.hiddenDangerCatetoryList.forEach(item=>{
                    xdata.push(item.hidden_danger_category_name);
                    ydata.push(item.total);
            });
            return { xdata, name: '隐患分类分布', 
                ydata: [
                    {name: '隐患数量', color: '#2385FF', data: ydata, barWidth: 10, barCategoryGap: 10,}
                ] 
            };
       },
       dangerControlStatusListFormat(){
            let xdata = [];
            let hiddenDanger = {};

            this.enterpriseHiddenDangerList.forEach(item=>{
                xdata.push(item.enterprise_name);

                const {
                    hidden_danger_control_status_info:  danger_status,
                } = item.hidden_danger_info;

                Object.keys(danger_status).forEach( key => {
                    hiddenDanger[key] = hiddenDanger[key] ? hiddenDanger[key] : {name: '', color: '', data: []};
                    hiddenDanger[key].name = HiddenDangerName[key].name;
                    hiddenDanger[key].color = HiddenDangerName[key].color;
                    hiddenDanger[key].data.push(danger_status[key]);
                })
           });
            return   { xdata, name: '整改情况', ydata: Object.values(hiddenDanger).reverse() };
       }
    },
    mounted(){
        //页面初始化接口
        this.initData();
    },
    methods:{
        //页面初始化
        initData(){
            this.getEnterprises();
            this.getHiddenDangerBaseInfo();
            this.getEnterpriseHiddenDangerList();
        },

        //右上角时间选择
        onDateChange(range){
            this.dateRange = range;
            this.getHiddenDangerBaseInfo();
            this.getEnterpriseHiddenDangerList();
        },

        //获得企业信息列表
        async getEnterprises(){
            //04-基础-获得下属企业信息列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getEnterpriseBaseList');
            if(!err && res){
                 this.enterpriseList = res.data.enterprise_list || [];
            }
        },

         //获得区域隐患基础信息 右上隐患原因分布，右下隐患分类分布
        async getHiddenDangerBaseInfo(){
            //11-隐患-获得区域隐患基础信息
            let [res, err] = await this.$Fx('/Api_Area/HiddenDanger/HiddenDanger/getHiddenDangerBaseInfo', this.dateRange);
            if(!err && res){
                // 隐患原因分布
                this.hiddenDangerSourceList = res.data.hidden_danger_base_info.hidden_danger_source_list || [];
                // 隐患等级
                this.hiddenDangerLevelList =  res.data.hidden_danger_base_info.hidden_danger_level_list || [];
                //隐患分类
                this.hiddenDangerCatetoryList =  res.data.hidden_danger_base_info.hidden_danger_catetory_list || [];
            }
        },
         //获得下属企业隐患信息列表 右侧第一个柱状图
        async getEnterpriseHiddenDangerList(){
            //12-隐患-获得下属企业隐患信息列表
            let [res, err] = await this.$Fx('/Api_Area/HiddenDanger/HiddenDanger/getEnterpriseHiddenDangerList', this.dateRange);
            if(!err && res){
               this.enterpriseHiddenDangerList = res.data.enterprise_hidden_danger_list; 
               this.setMapData();
            }
        },

        // 设置map的marker信息
        setMapData(){
            const markers = this.enterpriseHiddenDangerList.map(item => {
               const {  
                    enterprise_id,
                    enterprise_name,
                    grid_info:{
                        longitude: long, // 经度
                        latitude: lat // 维度
                    },
                    enterprise_hidden_danger_level_id: level_id,
                    hidden_danger_info:{
                        hidden_danger_control_status_info: {
                            doing_total: doing, //整改中
                            again_total: again, //再整改
                            overdue_unfinish_total: overdue, //逾期未整改
                        },
                        hidden_danger_level_list: level_list,  //隐患级别数组
                        hidden_danger_total: total //隐患总数
                    }
               } = item;

               return {
                    enterprise_id,
                    enterprise_name,
                    long,
                    lat,
                    level_id,
                    level_name: riskNameType[`danger${level_id}`].name,
                    level_color: riskNameType[`danger${level_id}`].color,
                    type: '隐患',
                    info: { total, doing, again, overdue, level_list } 
               }
           })

           EventBus.$emit("changeMarkers", markers);
        },

        //点击隐患台账清单
        onLedgerClick(item){
            const isActive = this.ledgerActive && item.key === this.ledgerActive.key;
            this.ledgerActive =  isActive ? null : item;
        },

        //点击表格关闭按钮
        onTableClose(flag){
            this.ledgerActive = flag;
        },

        //点击企业列表中的某个企业
        onShopClick(item){
            const isActive = this.shopActive && item.enterprise_id === this.shopActive.enterprise_id;
            this.shopActive =  isActive ? null : item;

            const { grid_info: { latitude: lat, longitude: long } } = item;
            EventBus.$emit("moveCenter", { lat, long } );
        },

        //搜索企业列表
        onShopSearch(value){
           this.shopList = value ? this.enterpriseList.filter( item=> item.enterprise_name.includes(value) ) : [];
        },
    } 
}
</script>

<style lang="less">
.hidden-danger-view{
    height: 100%;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .ledger-shopsearch{
        display: flex;
        flex-direction: column;
        padding: 60px 0 0 0px;
    }
    .table-charts-view{
        flex: 1 1 auto;
        padding-left: 15px;
        &.charts{
            display: flex;
            justify-content: flex-end;
        }
        .table-modal{
            width: 100%;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
        .charts-modal{
            width: 325px;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
    }
    .footer-cricle-view{
        // border: 1px solid #aaa;
        position: absolute;
        bottom: 20px;
        left: 20px;
        pointer-events: all;
        display: flex;
    }
}
</style>
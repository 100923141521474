<template>
    <BorderBox :bg="true">
        <div class="single-bar-charts">
            <Title :text="title" />
            <div class="chart-view">
                <BarChartHor :id="id" :data="data" v-if="data && data.xdata.length > 0"/>
            </div>
        </div>
    </BorderBox>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';
import BarChartHor from '@/components/BarChartHor.vue';
import Title from '@/components/Title.vue';

import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';

import { GridComponent,TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

export default {
    name: 'SingleBarCharts',
    components: { BorderBox, BarChartHor, Title },
    props: ['data', 'id', 'title'],
}
</script>

<style lang="less">
.single-bar-charts{
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    .chart-view{
        flex: 1 1 auto;
      height: 100%;
    }
}
</style>

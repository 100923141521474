<template>
    <BorderBox :bg="true">
        <div class="table-list">
            <Title :text="title" class="title-close">
                <div class="close" @click="closeTable"> <i class="el-icon-close"></i> </div> 
            </Title>    
            <FilterList @onFilterSearch="onFilterSearch"/>
            <TableList :tableData="tableData" />
            <Pagination :data="pagination" @onSizeChange="onSizeChange" @onCurrentChange="onCurrentChange" />
        </div>
    </BorderBox>
</template>

<script>

import Title from '@/components/Title.vue';
import FilterList from './FilterList.vue';
import TableList from './TableList.vue';
import Pagination from '@/components/PaginationVew.vue';

import BorderBox from '@/components/BorderBox.vue';

export default {
    name: 'table-list',
    components: { Title, FilterList, TableList, Pagination, BorderBox },
    props: ['title', 'onClick'],
    data(){
        return {
            tableData: [
                // {
                //     No, //序号   
                //     enterprise_name, //企业  
                //     risk_point_name, //风险点   
                //     object_name, //排查对象   
                //     hidden_check_content_trouble_desc, //隐患内容
                //     hidden_check_content_control_content: "及时整改", //整改细则
                //     hidden_check_finish_date: "",  //整改完成时间
                //     hidden_check_submit_info: {    //隐患提报信息
                //         hidden_check_submit_user_name: "贾艳萍", //提报人
                //         hidden_check_submit_date: "2021-05-16"  //提报时间
                //     },
                //     hidden_check_charge_info: {  //隐患整改信息
                //         hidden_check_charge_user_name: "张培鸣",   // 整改责任人
                //         hidden_check_charge_user_department_id: "1432", // 整改部门id
                //         hidden_check_charge_user_department_name: "鼓冷一班", //整改部门
                //         hidden_check_rectify_date: "2021-05-16 00:00:00" //整改时间
                //     },
                //     hidden_check_content_control_status_name: {  //隐患整改状态
                //         hidden_check_content_control_status_id: "40005",
                //         hidden_check_content_control_status_name: "逾期未整改" //整改状态
                //     },
                //     hidden_danger_level_info: {  //隐患等级
                //         hidden_check_hidden_danger_level_id: "2",
                //         hidden_check_hidden_danger_level_name: "一般隐患"
                //     }
                // }    
            ],
            filters: {
                enterprise_id: '',          // 企业id
                department_id: '',          // 企业下部门id 
                start_date: '',             // 开始时间 yyyy-MM-dd
                end_date: '',               // 结束时间 yyyy-MM-dd
                hidden_danger_level_id: '', // 隐患等级id
                hidden_check_content_control_status_id: '',  // 隐患状态id
            },
            pagination: {  //分页信息
                page: 1,  // 页数(0-全部)
                count: 10, // 每页数量
                total: 0
            }
        }
    },
    mounted() {
        this.getHiddenDangerAccountList();
    },
    methods: {
        //关闭表格事件
        closeTable(){
            this.$emit('onClick', null);
        },

        // 过滤查询
        onFilterSearch(filter){
           this.filters = filter;
           //重新更新表格数据
           this.getHiddenDangerAccountList();
        },

        // 分页组件页面size变化
        onSizeChange(count){
            this.pagination = {...this.pagination, count};  
            this.getHiddenDangerAccountList();
        },

        // 分页组件页面当前页变化
        onCurrentChange(page){
            this.pagination = {...this.pagination, page};
            this.getHiddenDangerAccountList();
        },

        //获得区域风险表格数据
        async getHiddenDangerAccountList(){
            //13-隐患-获得隐患台账清单列表
            const params = {...this.filters, pagination: this.pagination};
            let [res, err] = await this.$Fx('/Api_Area/HiddenDanger/HiddenDanger/getHiddenDangerAccountList', params);
            if(!err && res){
                this.tableData = res.data.hidden_danger_account_list || [];
                //更新分页信息
                const { count, total} = res.data.paginated;
                this.pagination = { ...this.pagination, count: Number(count), total: Number(total) };
            }
        }
    },
}
</script>

<style lang="less">
.hidden-danger-view{
    .table-list{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }
}

.title-close{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    padding-bottom: 15px;
    padding-left: 10px;
    .close{
        width: 20px;
        height: 20px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
            transform: scale(1.2);
            color: #7DEBFF;
        }
    }
}

</style>
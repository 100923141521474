<template>
    <div class="date-range-mode">
        <BorderBox :bg="true">
            <div class="date-range-view">
                <Title :text="title" />
                <div class="dp">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        @change="change"
                    >
                    </el-date-picker>
                </div>
            </div>
        </BorderBox>
    </div>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';
import Title from '@/components/Title.vue';

export default {
    name: 'DataRangeMode',
    props:['title', 'onChange'],
    components: { BorderBox, Title },
    data(){
        return {
            dateRange: ''
        }
    },
    methods: {
        change(range){
            this.$emit('onChange', range);
        }
    }
}
</script>

<style lang="less" scope>
.date-range-mode{
    width: 100%;
    .date-range-view{
        pointer-events: all;
        border-radius: 5px;
        padding: 10px;
        .dp{
            padding-top: 10px;
            display: flex;
            justify-content: center;
            .el-input__inner{
                height: 30px;
                color: #fff;
                background: rgba(76, 156, 255, 0.2);
                border: 1px solid #3C79B1;
                border-radius: 3px;
                font-size: 12px;
            }
            .el-input__icon{
                line-height: 30px;
            }
            .el-date-editor{
                width: 220px;
                font-size: 12px;
                .el-range-input{
                    color: #fff;
                    font-size: 12px;
                    background: transparent;
                }
                .el-range-separator{
                    color: #fff;
                    line-height: 22px;
                }
                .el-range__icon{
                    position: relative;
                    top: -3px;
                }
                .el-range__close-icon{
                    position: relative;
                    top: -3px;
                    color: #fff;
                }
            } 
        }
    }
}
</style>
<template>
    <div class="chart-views">
        <div class="data-range-box">
            <DataRange title="统计时间" @onChange="onDateChange" />
        </div>
        <div class="hidden-danger-source-list-box">
            <HighchartsPie id="hiddenDangerSourceListChart" :data="hiddenDangerSourceList" title="隐患原因分布" v-if="hiddenDangerSourceList && hiddenDangerSourceList.length > 0"/>
        </div>

        <div class="hidden-danger-status-box" :style="{height:`${heightStatus}px`}">
            <SingleBarCharts id="dangerControlStatusList" :data="dangerControlStatusList" title="隐患状态分布"/>
        </div>

        <div class="hidden-danger-catetory-box" :style="{height:`${heightCatetory}px`}">
            <SingleBarCharts id="hiddenDangerCatetoryList" :data="hiddenDangerCatetoryList" title="隐患分类分布"/>
        </div>

    </div>
</template>

<script>

import HighchartsPie from '@/components/HighchartsPie.vue';
import SingleBarCharts from '@/components/SingleBarCharts.vue';
import DataRange from '@/components/DataRange.vue';

export default {
    name: 'HiddenDangerChartsView',
    components: { HighchartsPie, SingleBarCharts, DataRange },
    props: ['hiddenDangerSourceList', 'hiddenDangerCatetoryList', 'dangerControlStatusList', 'onChange'],
    data(){
        return {
            heightStatus: 350,
            heightCatetory: 300
        }
    },
    methods:{
        onDateChange(range){
            const [ start_date='', end_date='' ] = range || [];
            this.$emit('onChange', {start_date, end_date});
        }
    },
    watch:{
        hiddenDangerCatetoryList(cur){
            const h = cur.xdata.length * 15  + 30;
            this.heightCatetory =  Math.min(350, Math.max(200, h))
        },
        dangerControlStatusList(cur){
            const h = cur.xdata.length * 15  + 30;
            this.heightStatus =  Math.min(350, Math.max(200, h))
        }
    }
}
</script>

<style lang="less">
.chart-views{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .inherent-risk-box{
      height: 154px;
        //height:14vh;
    }
    .hidden-danger-source-list-box{
        height: 154px;
      //height:14vh;
        margin: 10px 0 0 0;
    }
  //隐患状态分布
//#dangerControlStatusList{
//  height: 300px;
//  overflow-y: auto;
//  overflow-x: hidden;
//  margin:10px 0
//}
  //隐患分类分布
  //#hiddenDangerCatetoryList{
  //  height: 300px;
  //  overflow-y: auto;
  //  overflow-x: hidden;
  //  margin:10px 0
  //}
  //隐患状态发布
    .hidden-danger-status-box{
     min-height: 200px;

      margin: 10px 0;
    }
//隐患分类分布
    .hidden-danger-catetory-box{
min-height: 200px;
      margin: 10px 0;
    }
}
</style>

<template>
  <div class="result-table">
      <Table :tableData="tableData" className="hidden-danger-table">
        <el-table-column
        prop="No"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="enterprise_name"
        label="企业"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="risk_point_name"
        label="风险点"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="object_name"
        label="排查对象"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="hidden_check_content_trouble_desc"
        label="隐患内容"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="hidden_check_content_trouble_desc"
        label="隐患等级"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span :style="{color: TABLE_RISK_LEVEL_COLOR[scope.row.hidden_danger_level_info.hidden_check_hidden_danger_level_name]}">
            {{ scope.row.hidden_danger_level_info.hidden_check_hidden_danger_level_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="提报日期"
        width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
         {{scope.row.hidden_check_submit_info.hidden_check_submit_date}}
        </template>
      </el-table-column>
      <el-table-column
        label="提报人"
        width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
         {{scope.row.hidden_check_submit_info.hidden_check_submit_user_name}}
        </template>
      </el-table-column>
      <el-table-column
        label="整改责任人"
        width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
         {{scope.row.hidden_check_charge_info.hidden_check_charge_user_name}}-{{scope.row.hidden_check_charge_info.hidden_check_charge_user_department_name}}
        </template>
      </el-table-column>
      <el-table-column
        label="整改期限"
        width="150"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
         {{scope.row.hidden_check_charge_info.hidden_check_rectify_date}}
        </template>
      </el-table-column>
      <el-table-column
        prop="hidden_check_content_control_content"
        label="整改措施"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="整改状态"
        width="60"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
            <div v-if="scope.row.hidden_check_content_control_status_name.hidden_check_content_control_status_name === '已整改'">
              <el-popover
                placement="bottom-end"
                width="300"
                trigger="click"
                popper-class="table-tooltips"
              >
                <div>
                    整改完成时间: {{scope.row.hidden_check_finish_date}}
                </div>
                <div class="status finish" slot="reference" >
                  {{ scope.row.hidden_check_content_control_status_name.hidden_check_content_control_status_name }}<i class="el-icon-warning"></i>
                </div>
              </el-popover>
            </div>
            <div v-else 
              class="status" 
            >
                {{ scope.row.hidden_check_content_control_status_name.hidden_check_content_control_status_name }}
            </div>
        </template>
      </el-table-column>
      </Table>  
  </div>
</template>

<script>

import Table from '@/components/Table.vue';

export default {
  name: "TableList",
  props: ['tableData'],
  components: { Table },
  data(){
    return {
      TABLE_RISK_LEVEL_COLOR: {
        '重大隐患': '#E86452',
        '一般隐患': '#FFD470',
      }
    }
  }
};
</script>

<style lang="less">
.result-table{
  flex: 1 1 auto;
  overflow: auto;

  .hidden-danger-table{
      .status{
         cursor: pointer;
         color: #FFB462;
         &.finish{
           color: #fff;
         }
        .el-icon-warning{
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          margin-left: '2px';
        }
        &:hover{
          .el-icon-warning{
            color: #7DEBFF;
          }
        }
      }
    }
}
</style>
<template>
  <div class="filter-box">
    <div class="filter-group">
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.enterprise_id" 
            filterable 
            clearable 
            @change="onEnterpriseChange"
            placeholder="请选择企业" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in enterprise_list"
                    :key="item.enterprise_id"
                    :label="item.enterprise_name"
                    :value="item.enterprise_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.depatment_id" 
            filterable
            clearable  
            placeholder="请选择隐患整改部门" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in department_list"
                    :key="item.depatment_id"
                    :label="item.department_name"
                    :value="item.depatment_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="dateChange"
            >
            </el-date-picker>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.hidden_danger_level_id" 
            filterable 
            clearable 
            placeholder="请选择隐患等级" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in hidden_danger_level_list"
                    :key="item.hidden_danger_level_id"
                    :label="item.hidden_danger_level_name"
                    :value="item.hidden_danger_level_id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="filter-item">
            <el-select 
            v-model="filterGroups.hidden_check_content_control_status_id" 
            filterable 
            clearable 
            placeholder="请选择整改状态" 
            popper-class="risk-table-filter-popper">
                <el-option
                    v-for="item in hidden_check_content_control_status_list"
                    :key="item.hidden_check_content_control_status_id"
                    :label="item.hidden_check_content_control_status_name"
                    :value="item.hidden_check_content_control_status_id"
                >
                </el-option>
            </el-select>
        </div>
    </div>
    <div class="btn-box">
        <el-button @click="onReset">重置</el-button>
        <el-button @click="onSearch">查询</el-button>
    </div>
</div>
</template>

<script>
export default {
    name: 'FilterList',
    props: ['onFilterSearch'],
    data(){
        return {
            enterprise_list: [], //企业列表
            department_list: [], //企业下-部门列表
            risk_point_list: [], //企业下-风险点列表
            hidden_danger_level_list: [], //隐患等级列表
            hidden_check_content_control_status_list: [], //隐患状态列表
            filterGroups: {
                enterprise_id: '',          // 企业id
                department_id: '',          // 企业下部门id 
                start_date: '',             // 开始时间 yyyy-MM-dd
                end_date: '',               // 结束时间 yyyy-MM-dd
                hidden_danger_level_id: '', // 隐患等级id
                hidden_check_content_control_status_id: '',  // 隐患状态id
            },
            dateRange: null, //日期
        }
    },
    mounted(){
        this.initFilter();
    },
    methods:{
        initFilter(){
            this.getEnterpriseBaseList();
            this.getHiddenDangerLevelList();
            this.getHiddenDangerControlStatusList();
        },

        // 日期修改
        dateChange(range){
            const [ start_date='', end_date='' ] = range || [];
            this.filterGroups = { ...this.filterGroups, start_date, end_date}
        },

        // 查询
        onSearch(){
            const {
                enterprise_id,          // 企业id
                depatment_id: department_id, // 企业下部门id 
                start_date,             // 开始时间 yyyy-MM-dd
                end_date,               // 结束时间 yyyy-MM-dd
                hidden_danger_level_id, 
                hidden_check_content_control_status_id
            } = this.filterGroups;

            const filter = {
                enterprise_id,  
                department_id, 
                start_date,
                end_date, 
                hidden_danger_level_id,
                hidden_check_content_control_status_id
            }

            this.$emit('onFilterSearch', filter)
        },

        // 重置
        onReset(){
            this.filterGroups = {
                enterprise_id: '',          // 企业id
                department_id: '',          // 企业下部门id 
                start_date: '',             // 开始时间 yyyy-MM-dd
                end_date: '',               // 结束时间 yyyy-MM-dd
                hidden_danger_level_id: '', // 隐患等级id
                hidden_check_content_control_status_id: '',  // 隐患状态id
            };

            this.dateRange = null;
        },

        //企业下拉框数据变化时候，需要更新 企业下-部门列表 和 企业下-风险点列表
        onEnterpriseChange(){
            this.getDepartmentListInEnterprise();
        },

        //获得企业列表
        async getEnterpriseBaseList(){
            // 04-基础-获得下属企业信息列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getEnterpriseBaseList');
            if(!err && res){
                this.enterprise_list = res.data.enterprise_list || [];
            }
        },

        //获得企业下-部门列表
        async getDepartmentListInEnterprise(){
            const enterprise_id = this.filterGroups.enterprise_id;
            if(!enterprise_id){
                this.risk_point_list = [];
                return ;
            }
            // 09-风险-风险-获得企业风险点列表
            const params = { enterprise_id };
            // 05-基础-获得下属某企业部门列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getDepartmentListInEnterprise', params);
            if(!err && res){
                this.department_list = res.data.department_list || [];
            }
        },

        //隐患等级列表
        async getHiddenDangerLevelList(){
            //14-隐患-获得隐患等级列表
            let [res, err] = await this.$Fx('/Api_Area/Base/HiddenDanger/getHiddenDangerLevelList');
            if(!err && res){
                this.hidden_danger_level_list = res.data.hidden_danger_level_list || [];
            }
        },

        //获得隐患状态列表
        async getHiddenDangerControlStatusList(){
            //15-隐患-获得隐患状态列表
            let [res, err] = await this.$Fx('/Api_Area/Base/HiddenDanger/getHiddenDangerControlStatusList');
            if(!err && res){
                this.hidden_check_content_control_status_list = res.data.hidden_check_content_control_status_list || [];
            }
        }

    }
}
</script>

<style lang="less" scope>
.hidden-danger-view{
    .filter-box{
        display: flex;
        justify-content: space-between;
        .filter-group{
            display: flex;
            .filter-item{
                padding: 0 8px;
            }
            .el-input__inner{
                height: 30px;
                color: #fff;
                background: rgba(76, 156, 255, 0.2);
                border: 1px solid #3C79B1;
                border-radius: 3px;
                font-size: 12px;
            }
            .el-input__icon{
                line-height: 30px;
            }
            .el-date-editor{
                width: 220px;
                font-size: 12px;
                .el-range-input{
                    color: #fff;
                    font-size: 12px;
                    background: transparent;
                }
                .el-range-separator{
                    color: #fff;
                    line-height: 22px;
                }
                .el-range__icon{
                    position: relative;
                    top: -3px;
                }
                .el-range__close-icon{
                    position: relative;
                    top: -3px;
                    color: #fff;
                }
            } 
        }
        .btn-box{
            display: flex;
            padding-left: 10px;
            .el-button{
                border: 1px solid #3C79B1;
                background: #154382;
                border-radius: 5px;
                color: #fff;
                padding: 4px 20px;
                transition: all 0.3s;
                &:hover{
                    background-color: rgb(41, 136, 245);
                }
            }
        }
    }
}

.risk-table-filter-popper {
  background: rgba(4, 17, 34, 0.9);
  border: 1px solid #3c79b1;
  border-radius: 5px;
  color: #fff;
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      height: 30px;
      color: #fff;
      line-height: 30px;
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .popper__arrow {
    border-bottom-color: #3c79b1 !important;
    &::after {
      border-bottom-color: rgba(4, 17, 34, 0.9) !important;
    }
  }
}
</style>